import React from 'react';
import AppRouter from "./router/router";

function App() {
    return (
        <AppRouter/>
    );
}

export default App;
